import { graphql } from 'gatsby'
import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'

function Vegetarian({ toppings }) {
	const vegetarian = toppings.every((topping) => topping.vegetarian === true)

	if (vegetarian) {
		return <span className='blink_me'>Yes!</span>
	} else {
		return <span>no &#x1F625;</span>
	}
}

export default function SinglePizzaPage({ data }) {
	const { pizza } = data
	return (
		<div>
			<h2>
				<span className='mark'>{pizza.name}</span>
			</h2>
			<p>{pizza.toppings.map((topping) => topping.name).join(', ')}</p>
			<p>
				I'm a vegetarian! Can I have this pizza?{' '}
				<Vegetarian toppings={pizza.toppings} />
			</p>
			<Img fluid={pizza.image.asset.fluid} alt={pizza.name}></Img>
		</div>
	)
}

//needs to be dynamic based on the slug passed in via gatsby node
export const query = graphql`
	query ($slug: String!) {
		pizza: sanityPizza(slug: { current: { eq: $slug } }) {
			name
			id
			image {
				asset {
					fluid(maxWidth: 800) {
						...GatsbySanityImageFluid
					}
				}
			}
			toppings {
				name
				id
				vegetarian
			}
		}
	}
`
